<template>
	<div style="background: #f5f5f5;">
		<qy-top-nav :title="isProxyOrder?'订单列表':'我的订单'" />
		<van-tabs v-model="status" @change="refresh()">
			<van-tab title="全部" name="-1"></van-tab>
			<van-tab title="待付款" name="0"></van-tab>
			<van-tab title="待发货" name="1"></van-tab>
			<van-tab title="待签收" name="2"></van-tab>
			<van-tab title="已完成" name="3"></van-tab>
			<!-- <van-tab title="退货中" name="4"></van-tab> -->
		</van-tabs>
		<div :style="{height: containerHeight}">
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="100" @load="loadMore()">

				<div v-if="dataList.length" v-for="(order,orderIndex) in dataList" @click="openDetail(order.orderId)"
					style="border-radius: 6px; background: #fff; margin: 10px; padding-bottom: 10px;">
					<template v-if="order.goodsList&&order.goodsList.length>0">
						<div v-if="isProxyOrder"
							style="font-size: 16px; font-weight: bold; padding: 12px 0;text-align:center;border-bottom:1px solid #ededed; margin-bottom: 10px; color: red; ">
							<i class="van-icon van-icon-manager prt2" /> {{order.userName}}({{order.userPhone}})
						</div>
						<div
							style="line-height: 50px;font-size: 18px;font-weight: bold;color: #333;padding-left: 5px;color: red;;">
							<i class="van-icon van-icon-send-gift prt2" /> {{order.shopName||'平台'}}
							<!-- <i class="van-icon van-icon-arrow prt2" /> -->
							<span
								style="float:right;color:orange;margin-right: 20px;">{{statusDesc[order.status]}}</span>
						</div>
						<div style="border-top:1px solid #ededed;padding-top: 6px;">
							<div v-for="(item,goodsIndex) in order.goodsList" :key="goodsIndex"
								style="border-bottom: 1px solid #ededed;padding-left:10px;padding-top:6px;display: flex; align-items: center; position: relative; padding-bottom: 10px;">
								<div style="flex-shrink: 0; margin: 0 10px ;">
									<img :src="getImgUrl(item.goodsLogo)" alt=""
										style="width: 90px; height: 90px; border-radius: 5px;">
								</div>

								<div class="" style="flex-grow: 1;">
									<div>
										<div>{{item.goodsName}} </div>
										<div style="color: #666;  padding: 8px 2px; margin-top: 0px;">
											{{getInstanceDesc(item,false)}}
										</div>
										<div style="margin: 0px; overflow: hidden; padding: 5px 0px;">
											<span class="goodsPrice"
												style="float: left; right: 10px; display: block; font-size: 14px; color: #c00;color: red; font-weight: bold;"><b>￥</b>{{qy.formatMoney(item.goodsDealerPrice > 0 ? item.goodsDealerPrice : item.goodsPrice)}}</span>
											<span style="margin-left: 12px; color: #999;">
												<i class="van-icon van-icon-cross prt2" />{{item.goodsNum}}
											</span>
										</div>
									</div>
									<div>
										<van-button size="mini" plain round
											v-show="!isProxyOrder&&order.status==3&&item.commentStatus==0" type="info"
											@click.stop="addComment(item)">评价</van-button>
										<van-button size="mini" plain round type="primary"
											v-show="!isProxyOrder&&order.status==3&&item.afterSaleStatus !=1"
											@click.stop="afterSale(order,item)">售后</van-button>
										<van-button size="mini" plain round type="primary" disabled
											v-show="order.status==3&&item.afterSaleStatus ==1">售后处理中...</van-button>
									</div>
								</div>
							</div>
						</div>
						<div class="flex-between"
							style="font-size: 14px;font-weight: bold;letter-spacing: 1px;padding-top:10px;padding-right: 10px;color: red;align-items: center;">
							<span style="color: #333;padding-left: 12px;"> <van-icon name="tosend" class="prt2" size="16" /> {{order.createTime}}</span>
							<span><span style="font-size: 12px"> ¥</span>{{qy.formatMoney(order.orderMoney)}}</span>
						</div>
						<div
							style="margin-top: 10px;border-top:1px solid #ededed;text-align: right;padding:6px 10px 5px; ">
							<van-button size="small" plain type="danger" @click.stop="openPayType(order.orderId)"
								class="ml6" v-show="!isProxyOrder&&order.status==0">去支付</van-button>
							<van-button size="small" plain type="info" @click.stop="cancelOrder(order.orderId)"
								class="ml6" v-show="order.status==0">取消订单</van-button>
							<van-button size="small" plain type="info" @click.stop="signOrder(order.orderId)"
								class="ml6" v-show="order.status==2">签收</van-button>
							<!-- <van-button size="mini" plain type="danger" @click.stop="toReturnPage(order.orderId)"class="ml6"
							v-show="order.status==1 || order.status == 2">退货</van-button>
						<van-button size="mini" plain type="danger" @click.stop="cancelReturnGoods(order.orderId)"class="ml6"
							v-show="order.status == 4">取消退货</van-button> -->
						</div>
					</template>
				</div>
			</van-list>
		</div>
		<!--支付方式-->
		<van-popup v-model="showPayPanel" position="bottom" closeable close-icon="close" round
			:style="{ height: 'auto' }" id="payTypePopup">
			<div style="text-align: center;padding:10px 0px;font-size:18px;">
				选择支付方式
			</div>
			<van-divider />
			<ul>
				<li :class="{ active: payType == 0 }" @click="getPayType(0)" style="position: relative">
						<span style="">{{ isInWx() ? '微信支付' : '支付宝' }}</span>
						<span v-if="balanceInfo.balance&&!isInWxMini" style=" position: absolute;right: 0; margin-right: 0.2rem">
							<label>
								余额：
								<span style="right:2px;color: red; font-size: 16px; margin-right: 5px">{{ formatMoney(balanceInfo.balance) }}</span>
							</label>
							<van-switch class="prt2" v-model="useBalance" size="14px" />
						</span>
				</li>
				<!-- <li :class="{active:payType==1}" @click="getPayType(1)">线下支付</li> -->
				<!-- <li :class="{active:payType==2}" @click="getPayType(2)">物流代收</li> -->
			</ul>
			<div style="padding:1.2% 5%;">
				<van-button block plain round size="normal" type="danger" @click="onSubmit">确认支付</van-button>
			</div>
		</van-popup>
	</div>
</template>
<script type="text/javascript">
	import request from "@/utils/request";
	export default {
		name: "orderList",
		components: {},

		data() {
			return {
				orderId: null,
				containerHeight: 0,
				totalPrice: 0,
				status: -1,
				statusDesc: {
					0: "待付款",
					1: "待发货",
					2: "待签收",
					3: "已完成",
					4: "退货处理中",
					5: "已退货",
					11: "已取消",
					12: "已超时"
				},
				balanceInfo: {},
				dataList: [],
				total: 0,
				disabled: false,
				payType: 0,
				useBalance: 0,
				showPayPanel: false,
				useBalance: false,
				isProxyOrder: 0,
				isInWxMini: false,
				loading: false,
				isRequesting:false,
				finished: false,
				pageNum: 1,
				pageSize: 10
			}
		},
		created() {
			if (top && top.location.href != location.href) {
				this.Toast.success(top.location.href)
				top.location.href = location.href;
			}
			this.status = this.getUrlParam("status");
			if (!this.status && this.status !== 0) {
				this.status = -1;
			}
			if (this.isProxy() && this.getPageUrl().indexOf("proxy") > -1) {
				this.isProxyOrder = 1;
			}
			//console.log(document.documentElement.clientHeight)
			this.containerHeight = document.documentElement.clientHeight - 120 + "px";
			let payUrl = this.storage.pop("payUrl");
			if (payUrl) {
				location.href = (decodeURIComponent(payUrl))
			}
			this.fetchBalance();
			this.isInWxMiniPro((rs) => {
				
				this.isInWxMini = rs;
			});


		},
		methods: {
			fetchBalance() {
				request.post("/api/balance").then(rs => {
					this.balanceInfo = rs.data;
				})
			},
			refresh() {
				this.pageNum = 1;
				this.dataList = [];
				this.finished = false;
				// this.loadMore();	
			},
			loadMore() {
				let status = this.status >= 0 ? this.status : "";
				if(this.isRequesting){
					return;
				}
				this.isRequesting=true;
				request.post("/api/order/list", {
					status: status,
					p: this.isProxyOrder,
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then(rs => {
					this.isRequesting=false;
					let data = rs.data;
					this.loading = false;
					if (data.rows.length < this.pageSize || data.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}
					this.pageNum++;
					this.dataList = this.dataList.concat(data.rows);
					this.statisticsData = data.otherData;
				})
			},
			cancelOrder(orderId) {
				this.Dialog.confirm({
					message: '确定要取消该订单吗?',
				}).then(() => {
					this.Toast.loading();
					request.post("/api/order/cancel", {
						orderId: orderId
					}).then(rs => {
						this.Toast.clear();
						if (rs.success) {
							this.Toast("取消订单成功");
							this.refresh()
						} else {
							this.Toast.fail(rs.msg);
						}
					})
				})
			},
			signOrder(orderId) {
				this.Dialog.confirm({
					message: '已收到货,确定要签收吗?',
				}).then(() => {
					this.Toast.loading()
					request.post("/api/order/sign", {
						orderId: orderId
					}).then(rs => {
						this.Toast.clear()
						if (rs.success) {
							this.Toast("订单签收成功");
							this.refresh()
						}else{
							this.Toast(rs.msg)
						}
					})
				})
			},
			toReturnPage(orderId) {
				this.$router.push('/goodsReturn?orderId=' + orderId);
			},
			returnGoods(orderId) {
				this.Dialog.confirm({
					message: '确定要退货吗?',
				}).then(() => {
					request.post("/api/order/returnGoods", {
						orderId: orderId
					}).then(rs => {
						if (rs.success) {
							this.Toast("已退货，等待商户处理...");
							this.refresh()
						}
					});
				})
			},
			cancelReturnGoods(orderId) {
				this.Dialog.confirm({
					message: '确定要取消退货申请吗?',
				}).then(() => {
					request.post("/api/order/quitReturn", {
						orderId: orderId
					}).then(rs => {
						if (rs.success) {
							this.Toast("取消退货成功");
							this.refresh()
						} else {
							this.Toast(rs.msg)
						}
					});
				})
			},
			addComment(item) {
				let shopId = item.shopId;
				let goodsLogo = item.goodsLogo;
				let goodsId = item.goodsId;
				let goodsInstanceId = item.goodsInstanceId;
				let orderId = item.shopOrderId;
				let orderNo = item.shopOrderNo;
				this.$router.push({
					name: 'AddComment',
					params: {
						orderId: orderId,
						orderNo: orderNo,
						shopId: shopId,
						goodsId: goodsId,
						goodsInstanceId: goodsInstanceId,
						goodsLogo: goodsLogo
					}
				});
			},
			afterSale(order, item) {
				let shopId = item.shopId;
				let goodsId = item.goodsId;
				let goodsInstanceId = item.goodsInstanceId;
				let orderId = item.shopOrderId;
				let orderDetailId = item.id;
				let goodsLogo = item.goodsLogo;
				let goodsName = item.goodsName;
				let consigneeName = order.consigneeName;
				let consigneePhone = order.consigneePhone;
				this.$router.push({
					name: 'AfterSale',
					params: {
						shopId: shopId,
						goodsId: goodsId,
						goodsInstanceId: goodsInstanceId,
						orderId: orderId,
						orderDetailId: orderDetailId,
						goodsLogo: goodsLogo,
						goodsName: goodsName,
						isInWxMini: false,
						consigneeName: consigneeName,
						consigneePhone: consigneePhone,
					}
				});
			},
			openDetail(orderId) {
				this.$router.push({
					path: "/orderDetail",
					query: {
						orderId: orderId
					}
				})
			},
			openPayType(orderId) {
				// this.Toast("isInWxMini:"+this.isInWxMini)
				// return;
				if (this.isInWxMini) {
					//跳转到微信支付
					this.openWxMiniPay({
						orderId: orderId,
						type: 1,
						callback: 'orderList?status=-1'
					});
					return;
				}
				this.orderId = orderId;
				this.showPayPanel = true;
			},
			getPayType(v) {
				this.payType = v;
			},
			onSubmit() {
				//线上支付-使用余额？
				let useBalance = 0;
				if (this.payType == 0) {
					useBalance = this.useBalance ? 1 : 0;
				}
				request.post("/api/order/payOrder", {
					orderId: this.orderId,
					payType: this.payType,
					useBalance: useBalance,
					redirectUrl: "https://" + location.hostname + "/orderList"
				}).then(rs => {
					if (rs.success) {

						let data = rs.data;
						if (!data) {
							this.Toast.success("支付成功");
							this.refresh();
						} else {
							if (this.isInWxMini) { //跳转到微信支付
								this.openWxMiniPay({
									orderId: this.orderId,
									type: 1,
									callback: "orderList?status=-1"
								});
								return;
							}
							this.openWxH5Pay(decodeURIComponent(data.payData));
						}

					} else {
						return this.Toast(rs.msg)
					}
				});
				this.showPayPanel = false;
			}
		},
		mounted() {
			// console.log(document.documentElement.clientHeight)
			this.containerHeight = document.documentElement.clientHeight - 120 + "px";
		},
	}
</script>
<style type="text/css" scoped="">
	#payTypePopup ul {
		font-size: 14px;
		margin-bottom: 0.1rem;
	}

	#payTypePopup>ul>li {
		text-align: center;
		padding: 12px 0px;
		margin: 0px;
		border-bottom: 1px #ddd solid;
		border-radius: 5px;
		
	}

	#payTypePopup>ul>li.active {
		background-color: #f7f7f7;
		color: #333;
	}





	.listDetails:after {
		content: " ";
		position: absolute;
		bottom: 0px;
		left: 100px;
		right: 10px;
		height: 1px;
		background: #ededed;
	}

	.van-checkbox {
		min-width: 24px;
	}


	.noData {
		font-size: 13px;
		margin-bottom: 20px;
		padding: 0.8em;
		text-align: center;
	}
</style>